import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {FreeDownloadEmailData} from '../model/free-download-email-data';
import {PaidDownloadEmailData} from '../model/paid-download-email-data';
import {VerifyCaptchaRequest} from '../model/verify-captcha-request';

@Injectable()
export class ApiService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  private sendPost<T>(
    url: string,
    options?: { params?; headers?; responseType? },
    body?: any,
    transform: (data) => any = data => data
  ): Observable<T> {
    return this.httpClient.post(`${this.baseUrl}/${url}`, body, options).pipe(map(transform));
  }

  public sendFreeDownloadEmail(emailData: FreeDownloadEmailData): Observable<void> {
    return this.sendPost('email/free-download', {}, emailData);
  }

  public sendPaidDownloadEmail(emailData: PaidDownloadEmailData): Observable<void> {
    return this.sendPost('email/paid-download', {}, emailData);
  }

  public verifyGoogleCaptcha(verifyCaptchaRequest: VerifyCaptchaRequest): Observable<boolean> {
    return this.sendPost('verifyCaptcha', {}, verifyCaptchaRequest);
  }
}
