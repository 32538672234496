<div class="pagemap-footer-wrapper">
  <div class="section pagemap-footer">
    <div class="pagemap-footer-item pagemap-footer-logo">
      <img src="assets/SM_logo.svg" width="140" alt="SourceMeter logo">
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/" (click)="onScrollToSection('features')">
        Features
      </a>
      <a *ngFor="let feature of features" class="pagemap-footer-column-item"
         routerLink="/" (click)="onScrollToSection(feature.id)">
        {{feature.name}}
      </a>
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/download">Download</a>
      <a *ngFor="let downloadPackage of downloadPackages" class="pagemap-footer-column-item uppercase"
         routerLink="/download" [queryParams]="{language: downloadPackage.downloadType.name}"
         [skipLocationChange]="">
        {{downloadPackage.downloadType.name}}
      </a>
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/"
         (click)="onScrollToSection('contact')">Contact</a>
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/news">News</a>
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/"
         (click)="onScrollToSection('customers')">Customers</a>
    </div>
    <div class="pagemap-footer-item pagemap-footer-column">
      <a class="pagemap-footer-column-title uppercase" routerLink="/terms-of-use">Terms of use</a>
    </div>
  </div>
</div>
