<sm-navbar [transparent]="true"></sm-navbar>

<div class="text-carousel" defaultImage="assets/open_page_laptop_optimized.png" lazyLoad="assets/open_page_laptop.png">
  <div class="sm-text-carousel-wrapper">
    <ngx-slick-carousel class="carousel sm-carousel sm-text-carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slick-slide">
        <h1 class="sm-text-carousel__title bold">{{slide.title}}</h1>
        <p class="text-gray">{{slide.description}}</p>
      </div>
    </ngx-slick-carousel>
  </div>
</div>

<section class="section section--gray section--bordered">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">What is SourceMeter?</h2>
  <p class="text-gray text-justify introduction">
    SourceMeter is an innovative tool built for the precise static source code analysis of C/C++, Java, C#, Python, and
    RPG projects. This tool makes it possible to find the weak spots of a system under
    development from the source code only, without the need of simulating live conditions. The tool integrates also the
    best of available free static checker tools (Cppcheck, PMD, FindBugs, FxCop,-
    Pylint) and presents their results in a unified way. Using the results of the analysis, the quality of the analyzed
    source code can be improved and developed both in the short– and long term in a directed
    way. Free version with limited functionality is available for all programming languages.
  </p>
</section>

<section class="section section--bordered companies">
  <div class="row">
    <div class="col-lg-6 companies__column">
      <div class="row h-100 align-items-center mb-2 mb-sm-0">
        <div class="col-sm-6 text-center">
          <img src="assets/FEA_kep.jpg" class="img-fluid" alt="FrontEndART office">
        </div>
        <div class="col-sm-6">
          <img src="assets/FEA_logo.svg" class="companies__title" alt="FrontEndART logo">
          <p class="text-gray">The expert in software quality</p>
          <a
            class="btn btn-outline-primary action-button action-button--outline bold m-0"
            href="https://frontendart.com/">READ MORE</a>
        </div>
      </div>
    </div>
    <div class="col-lg-6 companies__column mb-0">
      <div class="row h-100 align-items-center mb-2 mb-sm-0">
        <div class="col-sm-6 text-center">
          <img src="assets/QG_kep.jpg" class="img-fluid" alt="QualityGate homepage">
        </div>
        <div class="col-sm-6">
          <img src="assets/QG_logo_smaller.svg" class="companies__title" alt="QualityGate logo">
          <p class="text-gray">Software quality management solution</p>
          <a class="btn btn-outline-primary action-button action-button--outline bold m-0"
             href="https://www.quality-gate.com/">READ MORE</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="features" #featuresSection class="section section--bordered">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">Features</h2>
  <div class="row">
    <div class="feature col-md-6 col-lg-4" *ngFor="let feature of features" [id]="feature.id">
      <div class="icon-wrapper">
        <img [src]="feature.image" [alt]="feature.name">
      </div>
      <h2 class="title">{{feature.name}}</h2>
      <p class="description text-gray">{{feature.description}}</p>
    </div>
  </div>
</section>

<section class="section section--gray section--bordered">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">News</h2>
  <div class="text-left news">
    <div class="flex-container">
      <div class="col-2" *ngFor="let newsItem of newsItems">
        <div class="article-preview">
          <div class="article-preview__image-wrapper">
            <img class="article-preview__image-wrapper__img"
                 [src]="getImageUrl(newsItem.media?.formats?.thumbnail.url)"
                 [alt]="newsItem.media?.alternativeText || 'Article image'"/>
          </div>
        </div>
        <div class="article-preview__content">
          <p class="news-title">{{ newsItem.title }}</p>
          <p [innerHtml]="newsItem.abstractContent" class="text-gray">
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="newsItems?.length">
    <a class="btn btn-outline-primary action-button action-button--outline bold"
       [routerLink]="['/news']">READ MORE</a>
  </div>
</section>

<section #customersSection class="section section--bordered" id="customers">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">Customers</h2>
  <div class="sm-customers-carousel-wrapper">
    <ngx-slick-carousel
      class="sm-carousel sm-customers-carousel"
      #slickModal="slick-carousel"
      [config]="customersSlideConfig">
      <div ngxSlickItem class="sm-customers-carousel__slide" *ngFor="let customer of customers">
        <img class="sm-customers-carousel__img" [src]="getImageUrl(customer.Logo?.url)"
             [alt]="customer.Logo?.alternativeText || customer.Company || 'Reference logo image'"/>
      </div>
    </ngx-slick-carousel>
  </div>

</section>

<section #contactSection class="section section--gray section--bordered" id="contact">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">Contact</h2>
  <form #contactForm="ngForm" class="contact text-left" (ngSubmit)="onContactSubmit(contactForm)">
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="contactName">Name</label>
        <input id="contactName" name="name" ngModel #name="ngModel" required type="text"
               class="font-default weight-normal contact__input-field"
               [ngClass]="{ submitted: contactForm.submitted }"/>
        <div *ngIf="name.invalid && (name.dirty || name.touched || contactForm.submitted)"
             class="contact__alert" i18n="contact-name-required-alert">
          Name is required
        </div>
      </div>
      <div class="form-group col-lg-4">
        <label for="contactEmail">Email</label>
        <input id="contactEmail" name="email" ngModel #email="ngModel" required
               type="email" class="font-default weight-normal contact__input-field"
               [ngClass]="{ submitted: contactForm.submitted }"/>
        <div *ngIf="email.invalid && (email.dirty || email.touched || contactForm.submitted)">
          <div *ngIf="email.errors.required" class="contact__alert"
               i18n="contact-email-required-alert">
            Email address is required
          </div>
          <div *ngIf="email.errors.pattern" class="contact__alert"
               i18n="contact-email-invalid-alert">
            Invalid email address
          </div>
        </div>
      </div>
      <div class="form-group col-lg-4">
        <label for="contactPhone">Phone</label>
        <input id="contactPhone" name="phone" ngModel #phone="ngModel" required type="tel"
               class="font-default weight-normal contact__input-field"
               [ngClass]="{ submitted: contactForm.submitted }"/>
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched || contactForm.submitted)">
          <div *ngIf="phone.errors.required" class="contact__alert"
               i18n="contact-phone-required-alert">
            Phone number is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label for="contactMessage">Message</label>
        <textarea name="message" ngModel #message="ngModel" required id="contactMessage" rows="8"
                  class="font-default weight-normal contact__input-field contact__input-field--textarea"
                  [ngClass]="{ submitted: contactForm.submitted }"></textarea>
        <div *ngIf="message.invalid && (message.dirty || message.touched || contactForm.submitted)"
             class="contact__alert" i18n="contact-message-required-alert">
          Message is required
        </div>
      </div>
    </div>

    <div class="row" *ngIf="errorContactSubmit !== undefined">
      <div class="col contact__status text-center">
        <div *ngIf="errorContactSubmit === true" class="contact__status__message--failure"
             i18n="contact-message-failure">
          Failed to send message. Please try it later.
        </div>
        <div *ngIf="errorContactSubmit === false" class="contact__status__message--success"
             i18n="contact-message-success">
          Thank you for your message.
        </div>
      </div>
    </div>

    <div class="text-center">
      <captcha-info></captcha-info>
      <button type="submit" class="btn btn-primary action-button form-submit"
              [disabled]="contactForm.invalid || submitInProgress">
        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
              *ngIf="submitInProgress"></span>
        SEND
      </button>
    </div>
  </form>
</section>

<sm-navigation-footer></sm-navigation-footer>
<sm-footer></sm-footer>
