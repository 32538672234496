import {Feature} from "../model/feature";
import {HomeTextSlide} from "../model/home-text-slide";
import {DownloadPackage} from "../model/download-package";
import {PricingType} from "../model/pricing-type";
import {FeatureHighlight} from "../model/feature-highlight";

export class StaticData {
  static getHomeTextSlides(): HomeTextSlide[] {
    return [
      {
        title: 'Distinguish fake clones from real ones!',
        description: 'Improved code duplication detection'
      },
      {
        title: '15% less false positives...',
        description: '...thanks to the FaultHunter module'
      },
      {
        title: 'One step ahead of hackers',
        description: 'Find your weak spots using VulnerabilityHunter!'
      },
      {
        title: 'Installation and usage? Piece of cake!',
        description: 'You can keep using your usual environment.'
      },
      {
        title: 'Boost your measurements for FREE!',
        description: 'The basic version of SourceMeter is free'
      },
      {
        title: 'Measure your code...',
        description: '...with more precision than ever before!'
      }
    ];
  }

  static getFeatures(): Feature[] {
    return [
      {
        id: 'features-platform-independent',
        image: 'assets/features_icon1.svg',
        name: 'Platform-independent command line tools',
        description: 'Working on any system supporting Java Runtime Environment'
      },
      {
        id: 'features-transparent-integration',
        image: 'assets/features_icon2.svg',
        name: 'Transparent integration into build processes',
        description: 'No need for unnecessarily overcomplicated configuration, works with most build standards out of the box'
      },
      {
        id: 'features-static-code-analysis',
        image: 'assets/features_icon3.svg',
        name: 'Deep static code analysis',
        description: 'Calculates more than 60 types of source code metrics at component, file, package, class, and method levels'
      },
      {
        id: 'features-error-detection',
        image: 'assets/features_icon4.svg',
        name: 'Most accurate coding error detection out there',
        description: 'OWASP vulnerability issues and re-implemented, enhanced PMD coding rule violations'
      },
      {
        id: 'features-clone-detection',
        image: 'assets/features_icon5.svg',
        name: 'Advanced type 2 clone detection algorithm',
        description: 'Syntax-based code duplication detection and custom clone metric calculation'
      },
      {
        id: 'features-user-interface',
        image: 'assets/features_icon6.svg',
        name: 'Enhanced user interface by third party integration',
        description: 'Improved, customizable dashboards with new method-level hot-spot widgets and source code metrics'
      }
    ]
  }

  static getDownloadPackages(): DownloadPackage[] {
    return [
      {
        id: 'download-java',
        downloadType: {
          name: 'JAVA',
          description: 'SourceMeter for Java is a source code analysis tool able to perform the deep static source code analysis of complex Java systems. SourceMeter can analyze source code conforming to Java 10.'
        },
        remarks: [
          "For systems smaller than 100.000 lines of code",
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            selected: true,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PMD_SPOTBUGS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.VULNERABILITY_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.RTE_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.ANDROID_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ]
          },
          {
            pricingType: PricingType.BASIC,
            price: 349,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PMD_SPOTBUGS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.VULNERABILITY_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.RTE_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.ANDROID_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['2']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 1499,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PMD_SPOTBUGS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.VULNERABILITY_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.RTE_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.ANDROID_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ],
          }
        ]
      },
      {
        id: 'download-c',
        downloadType: {
          name: 'C/C++',
          description: 'SourceMeter for C/C++ is a source code analysis tool able to perform the deep static source code analysis of complex C/C++ systems. SourceMeter supports C++ language defined by the ISO/IEC 14882:2011 international standard extended with several new features from ISO/IEC 14882:2014, along with C language defined by the ANSI/ISO 9899:1990, ISO/IEC 9899:1999 and ISO/IEC 9899:2011 standards. Besides standard features, several GCC and Microsoft specific extensions are supported as well. SourceMeter uses a so-called compiler wrapping technique during source code analysis, in which the analysis is performed parallel with the build process transparently, by wrapping the calls to the compiler - without the need to modify the source code or the build system. The analysis results reflect the actual build configuration of the analyzed system.'
        },
        remarks: [
          "14 out of 60 source code metrics available",
          "For systems smaller than 100.000 lines of code",
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CPP_CHECK,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ],
            selected: true
          },
          {
            pricingType: PricingType.BASIC,
            price: 699,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CPP_CHECK,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true,
                remarks: ['2']
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['3']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 2999,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CPP_CHECK,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ]
          }
        ]
      },
      {
        id: 'download-csharp',
        downloadType: {
          name: 'C#',
          description: 'SourceMeter for C# is a source code analysis tool able to perform the deep static source code analysis of complex C# systems. SourceMeter can analyze source code conforming to C# 7.3 and earlier versions. The following can be analyzed: a solution (.sln), a project (.csproj) or a single source file (.cs). In case of a project or a solution, it is recommended to build it with pdb generation enabled before analysing, therefore FxCop can be run on the binaries in parallel.'
        },
        remarks: [
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FX_COP,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ],
            selected: true
          },
          {
            pricingType: PricingType.BASIC,
            price: 179,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FX_COP,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['1']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 749,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FX_COP,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ]
          }
        ]
      },
      {
        id: 'download-python',
        downloadType: {
          name: 'Python',
          description: 'SourceMeter for Python is a source code analysis tool able to perform the deep static source code analysis of complex Python systems. SourceMeter can analyze source code conforming to Python 2.7.x, 3.7.x and earlier versions. It is sufficient to set the source directory of the project to start a global analysis.'
        },
        remarks: [
          "For systems smaller than 100.000 lines of code",
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PYLINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ],
            selected: true
          },
          {
            pricingType: PricingType.BASIC,
            price: 349,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PYLINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['2']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 1499,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.PYLINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ]
          }
        ]
      },
      {
        id: 'download-js',
        downloadType: {
          name: 'JS',
          description: 'SourceMeter for JavaScript is a source code analysis tool able to perform the deep static source code analysis of complex JavaScript systems. SourceMeter can analyze source code conforming to ECMAScript 2015 (ECMAScript 6).'
        },
        remarks: [
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.ES_LINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ],
            selected: true
          },
          {
            pricingType: PricingType.BASIC,
            price: 179,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.ES_LINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['1']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 749,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.ES_LINT,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ]
          }
        ]
      },
      {
        id: 'download-rpg',
        downloadType: {
          name: 'RPG',
          description: 'SourceMeter for RPG is a source code analysis tool able to perform the deep static source code analysis of complex RPG systems. SourceMeter can analyze source code conforming to RPG III and RPG IV versions (including free-form). Two methods of input are supported: either raw source code or compiler listing. In case of using raw source code as input, the analyzer might not be able to calculate some of the code metrics or detect specific rule violations because the raw source contains less information than the compiler listing. For instance, cross references are detected with the help of compiler listing entries - this is why it is highly recommended to use compiler listings as input to get most complete analysis results. For constructing RPG compiler listing files, use RPG compiler version V6R1M0.'
        },
        remarks: [
          "10 out of 40 source code metrics available",
          "For systems smaller than 100.000 lines of code",
          "Limited support"
        ],
        pricingPackages: [
          {
            pricingType: PricingType.FREE,
            price: 0,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true,
                remarks: ['1']
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: false
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: false
              }
            ],
            selected: true
          },
          {
            pricingType: PricingType.BASIC,
            price: 999,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true,
                remarks: ['2']
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true,
                remarks: ['2']
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true,
                remarks: ['3']
              }
            ]
          },
          {
            pricingType: PricingType.PRO,
            price: 4249,
            supportedFeatures: [
              {
                featureHighlight: FeatureHighlight.METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.CLONES,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.METRICS_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.FAULT_HUNTER,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.USER_DEFINED_METRICS,
                supported: true
              },
              {
                featureHighlight: FeatureHighlight.SUPPORT,
                supported: true
              }
            ]
          }
        ]
      }
    ];
  }

  static getFeatureHighlightDescription(featureHighlight: FeatureHighlight): string {
    switch (featureHighlight) {
      case FeatureHighlight.METRICS:
        return 'Source code metrics are used to quantify different source code characteristics. FrontEndART SourceMeter computes source code metrics for the following source code element types: components (e.g. archives), source files, packages, class types (including classes, interfaces, enums and annotations) and methods. The calculated metrics are divided into 6 categories, which are the following:' +
          'Cohesion metrics measure the extent of coheresion between source code elements in the system.' +
          'Complexity metrics measure the complexity of given source code elements (typically algorithms).' +
          'Coupling metrics measure the amount of interdependencies between source code elements.' +
          'Documentation metrics measure the amount of comments and documentation of source code elements in the system.' +
          'Inheritance metrics measure the different aspects of the system\'s inheritance hierarchy.' +
          'Size metrics measure the basic properties of the analyzed system in terms of different cardinalities (e.g. number of code lines, number of classes or methods).';
      case FeatureHighlight.CLONES:
        return 'Code cloning (or copy-paste programming) is the copying of an existing piece of source code and pasting it somewhere else while performing smaller modifications on it. FrontEndART SourceMeter is capable of identifying Type-2 clones, i.e. code fragments that are structurally identical, but may differ in variable names, literals, identifiers, etc. Clones are tracked during the source code analysis of consecutive revisions of the analyzed software system. FrontEndART SourceMeter detects suspicious, inconsistently changing code copies referred to as "clone smells".';
      case FeatureHighlight.PMD_SPOTBUGS:
        return 'FrontEndART SourceMeter incorporates PMD (http://pmd.github.io) and SpotBugs (https://spotbugs.github.io/) for coding rule violation check and imports their results. SourceMeter also associates the issued rule violations with source code elements (i.e. methods, classes, packages, and components) and calculates metrics for the source code elements, which represent the amount of violations of each ruleset, rule and priority groups, respectively. In addition, SourceMeter uses an optimized PMD setting, where poorly performing PMD rule checks are disabled and all other rules are reprioritized by our software developer and QA experts. Please note that if a valid FaultHunter license key is available, the reimplemented PMD rules will be disabled and FaultHunter will provide the results instead. If no license key is available (e.g. when running the free version), then the original PMD rule violation checks will be executed.';
      case FeatureHighlight.METRICS_HUNTER:
        return 'FrontEndART SourceMeter lists the source code elements which violate a certain metric threshold value as coding issues (the thresholds can be fine-tuned). This way, the riskiest elements of the code can be found immediately (e.g. the most complex algorithms).';
      case FeatureHighlight.FAULT_HUNTER:
        return 'FaultHunter is the coding rule violation checker module of SourceMeter. This module makes it possible to identify common Java coding rule violations in the code (so-called bad practices) in a similar way as provided by the PMD tool. However, the algorithms implemented in the FaultHunter module work on the precise Abstract Semantic Graph of SourceMeter which results in higher precision and recall compared to other tools using rough syntactic analyzer.\n' +
          'FaultHunter reimplements many PMD rule violation checks, reporting less noise (false positives) and a number of valid problems not detected by PMD (true negatives). It also provides additional checks that are not available in PMD.';
      case FeatureHighlight.VULNERABILITY_HUNTER:
        return 'The VulnerabilityHunter module of SourceMeter can detect OWASP security vulnerabilities in Java web applications (Command Injection, Cross-Site Scripting, HTTP Response Splitting, LDAP Injection, Path Traversal, SQL Injection) which could be potentially used by attackers to steal sensitive information. The majority of these attacks build on the improper or lacking implementation of user input validation to achieve unwanted program behavior.';
      case FeatureHighlight.RTE_HUNTER:
        return 'The RTEHunter module of SourceMeter can detect potential runtime errors (e.g. NullPointerException) in Java applications. These runtime errors are represented by the instances of Java class RuntimeException. The detection is based on an interprocedural symbolic execution engine.';
      case FeatureHighlight.ANDROID_HUNTER:
        return 'AndroidHunter is the module of SourceMeter, which seeks Android specific coding rule violations. This module checks the Java sources of the Android projects for common Android specific faults and anti-patterns. AndroidHunter reimplements all the corresponding PMD rules and additionally it provides some checks that are not available in PMD. Such as the other modules of SourceMeter, AndroidHunter also works on the precise Abstract Semantic Graph which results in higher precision and recall compared to other tools with a rougher syntactic analysis.';
      case FeatureHighlight.USER_DEFINED_METRICS:
        return 'In addition to the built-in metrics, users can create their own metrics by using the UserDefinedMetrics (UDM) module. These new metrics can be established with a formula that can use any previous SourceMeter metric and can even depend on other custom user-defined metrics.';
      case FeatureHighlight.SUPPORT:
        return 'The limited-level support includes technical assistance regarding the installation and operation of SourceMeter through email communication and also includes the management of reported issues within a moderate time frame and providing simple workarounds if possible. The full-level support also includes short reaction times of two working days, providing dedicated and top priortity bugfix releases for our customers in order to allow them flawless business continuity.';
      case FeatureHighlight.CPP_CHECK:
        return 'FrontEndART SourceMeter incorporates Cppcheck (http://cppcheck.sourceforge.net/) for coding rule violation check and imports its results. SourceMeter also associates the issued rule violations with source code elements (i.e. functions, methods, classes, namespaces, and components) and calculates metrics for different source code elements, which represent the amount of violations of each ruleset, rule, and priority groups, respectively.';
      case FeatureHighlight.FX_COP:
        return 'FxCop is a free static code analysis tool from Microsoft that checks .NET managed code assemblies for conformance to Microsoft\'s .NET Framework Design Guidelines.';
      case FeatureHighlight.PYLINT:
        return 'FrontEndART SourceMeter incorporates Pylint (http://www.pylint.org/) for coding rule violation check and imports its results. SourceMeter also associates the issued rule violations with source code elements (i.e. methods, functions, classes, packages, and modules) and calculates metrics for the source code elements, which represent the amount of violations of each ruleset, rule, and priority groups, respectively.';
      case FeatureHighlight.ES_LINT:
        return 'FrontEndART SourceMeter incorporates ESLint (https://eslint.org/) for coding rule violation check and imports its results. SourceMeter also associates the issued rule violations with source code elements (i.e. functions, methods, classes, namespaces, and components) and calculates metrics for different source code elements, which represent the amount of violations of each ruleset, rule, and priority groups, respectively.';
      default:
        return '';
    }
  }
}
