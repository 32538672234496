<nav #navbar id="navbar" class="navbar navbar-expand-xl navbar-light sticky-top sm-navbar w-100"
     [class.sm-navbar--expanded]="!collapsed">
  <div #background class="sm-navbar__background"></div>
  <a routerLink="/">
    <img src="assets/SM_logo.svg" height="80" alt="SourceMeter logo">
  </a>
  <button class="navbar-toggler sm-navbar__toggler" type="button" aria-controls="navbarContent"
          [attr.aria-expanded]="!collapsed"
          aria-label="Toggle navigation" (click)="collapsed = !collapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse align-self-end" [class.collapse]="collapsed" id="navbarContent">
    <div class="navbar-nav ml-auto">
      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'Features'" routerLink="/"
         (click)="onScrollToSection('features')">Features</a>

      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'Download'" routerLink="/download">Download</a>

      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'Contact'" routerLink="/"
         (click)="onScrollToSection('contact')">Contact</a>

      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'News'" routerLink="/news">News</a>

      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'Customers'" routerLink="/"
         (click)="onScrollToSection('customers')">Customers</a>

      <a class="mx-lg-3 sm-navbar__item" [class.active]="activeItem === 'Terms of use'" routerLink="/terms-of-use">Terms
        of use</a>

      <a class="nav-link btn btn-primary text-white action-button action-button--shadow mx-lg-2 my-2 bold"
         href="https://demo.sourcemeter.com/">ONLINE DEMO</a>
      <a class="nav-link btn btn-secondary text-white action-button action-button--shadow ml-lg-2 my-2 bold"
         href="/download/">FREE DOWNLOAD</a>
    </div>
  </div>
</nav>
