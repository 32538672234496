<sm-navbar [activeItem]="'Download'"></sm-navbar>

<section class="section">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">Download</h2>
  <div class="type-selector" #typeSelector>
    <div *ngFor="let downloadPackage of downloadPackages; let i = index;" class="type-selector__box"
         [class.type-selector__box--active]="displayedPackage === downloadPackage"
         (click)="selectPackage(downloadPackage, downloadPackage.pricingPackages[0])">
      <span class="type-selector__title bold">{{downloadPackage.downloadType.name}}</span>
    </div>
  </div>
  <div class="type-details">
    <h3 class="bold">{{displayedPackage.downloadType.name}}</h3>
    <p class="text-gray">{{displayedPackage.downloadType.description}}</p>
  </div>
  <div class="features">
    <h4 class="bold">Features</h4>
    <div class="features__details">
      <div class="features__details__list">
        <div class="features__details__list__item"
             *ngFor="let pricingPackage of displayedPackage.pricingPackages; let first = first;">
          <div class="features__details__highlights" [class.features__details__highlights--additional]="!first">
            <div class="features__details__highlights__grid">
              <div *ngFor="let highlight of displayedPackageHighlights; let i = index;"
                   class="features__details__highlights__item" [style.grid-row]="i+3">
                {{highlight}}
                <p class="features__details__highlights__item__tooltip text-gray font-small">
                  {{getFeatureHighlightDescription(highlight)}}
                </p>
              </div>
            </div>
          </div>
          <div class="features__box">
            <p class="features__box__title bold">{{pricingPackage.pricingType}}</p>
            <p class="features__box__price">
              <span class="features__box__price__sign">$</span>
              <span class="features__box__price__amount">{{pricingPackage.price | number}}</span>
              <sub class="features__box__price__interval">/Year</sub>
            </p>
            <div class="features__box__icon__wrapper" *ngFor="let highlight of displayedPackageHighlights">
              <img src="assets/check.png" class="features__box__icon"
                   *ngIf="findSupportedFeature(pricingPackage, highlight)?.supported"
                   alt="Checkmark">
              <img src="assets/highlight_disabled.png" class="features__box__icon"
                   *ngIf="!(findSupportedFeature(pricingPackage, highlight)?.supported || false)"
                   alt="X">
              <span class="features__box__icon__remark bold"
                    *ngFor="let remark of findSupportedFeature(pricingPackage, highlight)?.remarks || []">
                {{remark}}
              </span>
            </div>
            <button class="btn btn-primary action-button bold features__box__buy"
                    (click)="selectPackage(displayedPackage, pricingPackage)"
                    [class.features__box__buy--selected]="pricingPackage.selected">
              {{pricingPackage.selected ? 'SELECTED' : 'SELECT'}}
            </button>
          </div>
        </div>
      </div>
      <div class="features__details__note">
        <ol>
          <li *ngFor="let remark of displayedPackage.remarks" class="text-gray font-small">{{remark}}</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="section text-left invoicing">
  <h4 class="bold">Invoicing details</h4>
  <p class="text-gray invoicing__disclaimer">Information below is used for billing and contact purposes only.</p>
  <form #invoicingForm="ngForm" (ngSubmit)="onDownloadFormSubmit()">
    <div class="invoicing__form">
      <div class="form-row" *ngIf="getFinalPrice() > 0">
        <div class="form-group col-md-6 first-column">
          <label for="company" class="text-gray">Company name</label>
          <input type="text" class="form-control" id="company" required
                 name="companyName"
                 [(ngModel)]="invoicingDetails.companyName">
        </div>
        <div class="form-group col-md-6">
          <label for="vat" class="text-gray">VAT number</label>
          <input type="text" class="form-control" id="vat" required name="vatNumber"
                 [(ngModel)]="invoicingDetails.vatNumber">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 first-column" *ngIf="getFinalPrice() > 0">
          <label for="country" class="text-gray">Country</label>
          <select id="country" class="form-control" required name="country"
                  [(ngModel)]="invoicingDetails.country">
            <option *ngFor="let country of countryNames">{{country}}</option>
          </select>
        </div>
        <div class="form-group col-md-6" *ngIf="getFinalPrice() > 0">
          <label for="name" class="text-gray">Contact name</label>
          <input type="text" class="form-control" id="name" required name="name"
                 [(ngModel)]="invoicingDetails.contactName">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2" *ngIf="getFinalPrice() > 0">
          <label for="zip" class="text-gray">Zip</label>
          <input type="text" class="form-control" id="zip" required name="zip"
                 [(ngModel)]="invoicingDetails.zip">
        </div>
        <div class="form-group col-md-4 first-column" *ngIf="getFinalPrice() > 0">
          <label for="city" class="text-gray">City</label>
          <input type="text" class="form-control" id="city" required name="city"
                 [(ngModel)]="invoicingDetails.city">
        </div>
        <div class="form-group col-md-6" [class.first-column]="getFinalPrice() === 0">
          <label for="email" class="text-gray">Contact email address</label>
          <input type="email" class="form-control" id="email" email required name="email"
                 [(ngModel)]="invoicingDetails.contactEmail">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 first-column" *ngIf="getFinalPrice() > 0">
          <label for="address" class="text-gray">Street address</label>
          <input type="text" class="form-control" id="address" required name="address"
                 [(ngModel)]="invoicingDetails.address">
        </div>
        <div class="form-group col-md-6 align-self-center">
          <label class="d-none d-md-inline-block"></label>
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="termsAndConditions" required name="terms"
                   [(ngModel)]="termsAccepted">
            <label class="custom-control-label text-gray" for="termsAndConditions">
              I accept the <a [routerLink]="'/terms-of-use'">terms and conditions</a>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="invoicing__summary">
      <h4 class="bold invoicing__summary__header">Selected packages</h4>

      <div class="invoicing__summary__table">
        <div class="invoicing__summary__table__column invoicing__summary__table__column--left">
          <p *ngFor="let package of getSelectedPackages()" class="invoicing__summary__package text-gray">
            <span class="invoicing__summary__package__pricing">{{package.pricingPackages[0].pricingType}}</span>
            {{package.downloadType.name}}
          </p>
        </div>

        <div class="invoicing__summary__table__column invoicing__summary__table__column--right">
          <p class="features__box__price">
            <span class="features__box__price__sign">$</span>
            <span class="features__box__price__amount">{{getFinalPrice() | number}}</span>
          </p>
          <div>
            <button type="submit"
                    class="btn btn-primary action-button form-submit text-uppercase bold invoicing__summary__submit"
                    [disabled]="invoicingForm.invalid || submitInProgress">
            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                  *ngIf="submitInProgress"></span>
              {{getFinalPrice() === 0 ? 'Download' : 'Buy it'}}
            </button>
            <captcha-info></captcha-info>
          </div>
        </div>
      </div>
    </div>

  </form>
</section>

<sm-navigation-footer></sm-navigation-footer>
<sm-footer></sm-footer>

<ng-template #formSubmitSuccess let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Thank you</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-gray">
      We have sent you an email with download instructions and further information, please check your inbox.
    </div>
  </div>
</ng-template>

<ng-template #formSubmitError let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      An error occurred, please try again.
    </div>
  </div>
</ng-template>
