<sm-navbar [activeItem]="'Terms of use'"></sm-navbar>
<section class="section">
  <div class="termsOfUse">
    <div class="termsOfUse__content">
      <iframe *ngIf="document"
        appIframeResizer
        width="100%"
        [srcdoc]="document"
        scrolling="no"
        frameborder="1"
      ></iframe>
    </div>
  </div>
</section>
