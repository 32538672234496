import {AfterViewInit, Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Feature} from "../../model/feature";
import {StaticData} from "../../util/static-data";
import {DownloadPackage} from "../../model/download-package";

@Component({
  selector: 'sm-navigation-footer',
  templateUrl: './navigation-footer.component.html',
  styleUrls: ['./navigation-footer.component.scss']
})
export class NavigationFooterComponent implements AfterViewInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  fragmentSubscription: Subscription = new Subscription();
  features: Feature[] = StaticData.getFeatures();
  downloadPackages: DownloadPackage[] = StaticData.getDownloadPackages();

  ngAfterViewInit(): void {
    this.fragmentSubscription = this.route.queryParams.subscribe(data => {
      setTimeout(() => {
        if (data.section) {
          this.onScrollToSection(data.section);
          this.router.navigate([], {
            queryParams: {
              section: null,
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        }
      }, 200);
    });
  }

  onScrollToSection(hash): void {
    if (this.router.url === '/' || this.router.url.indexOf('/?section=') === 0) {
      document.getElementById(hash).scrollIntoView({behavior: 'smooth', block: 'center'});
    } else {
      this.router.navigate([''], {queryParams: {section: hash}});
    }
  }

}
