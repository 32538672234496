import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {DownloadComponent} from "./download/download.component";
import {NewsComponent} from "./news/news.component";
import {TermsOfUseComponent} from "./terms-of-use/terms-of-use.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'download', component: DownloadComponent},
  {path: 'news', component: NewsComponent},
  {path: 'terms-of-use', component: TermsOfUseComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
