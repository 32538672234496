import {AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as countries from 'country-list';
import {DownloadPackage} from '../model/download-package';
import {PricingPackage} from '../model/pricing-package';
import {InvoicingDetails} from '../model/invoicing-details';
import {NgForm} from '@angular/forms';
import {ApiService} from '../backend/api/api.service';
import {StaticData} from '../util/static-data';
import {FeatureHighlight, SupportedFeature} from '../model/feature-highlight';
import {uniqueArray} from '../util/utils';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements AfterViewInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: string,
    private modalService: NgbModal,
    private recaptchaV3Service: ReCaptchaV3Service) {
  }

  @ViewChild('typeSelector')
  typeSelector: ElementRef;

  downloadPackages: DownloadPackage[] = StaticData.getDownloadPackages();
  displayedPackage: DownloadPackage = this.downloadPackages[0];
  displayedPackageHighlights: FeatureHighlight[] = this.getHighlightsOfPackage(this.displayedPackage);

  countryNames: string[] = countries.getNames();

  @ViewChild('invoicingForm')
  invoicingForm: NgForm;
  submitInProgress = false;
  formSubmitError = undefined;
  invoicingDetails: InvoicingDetails = {
    address: '',
    city: '',
    companyName: '',
    contactEmail: '',
    contactName: '',
    country: '',
    vatNumber: '',
    zip: ''
  };
  termsAccepted = false;
  @ViewChild('formSubmitSuccess')
  formSubmitSuccessModal: ElementRef;

  @ViewChild('formSubmitError')
  formSubmitErrorModal: ElementRef;

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.language) {
        const packageByName: DownloadPackage = this.downloadPackages.find(e => e.downloadType.name === params.language);
        if (packageByName) {
          setTimeout(() => this.selectPackage(packageByName, packageByName.pricingPackages[0]), 0);
        }
        // we are removing the query param this way
        this.router.navigate([], {
          queryParams: {
            language: null,
          },
          replaceUrl: true,
          queryParamsHandling: 'merge',
        });
      }
      if (isPlatformBrowser(this.platformId)) {
        this.typeSelector.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    });
  }

  selectPackage(downloadPackage: DownloadPackage, pricingPackage: PricingPackage): void {
    downloadPackage.pricingPackages.forEach(e => e.selected = false);
    pricingPackage.selected = true;
    this.displayedPackage = downloadPackage;
    this.displayedPackageHighlights = this.getHighlightsOfPackage(this.displayedPackage);
  }

  getSelectedPackages(): DownloadPackage[] {
    const selectedPackages: DownloadPackage[] = [];
    this.downloadPackages.forEach(e => {
      const selectedPricingPackage = e.pricingPackages.find(e => e.selected);
      if (selectedPricingPackage) {
        selectedPackages.push({
          id: e.id,
          downloadType: e.downloadType,
          pricingPackages: [selectedPricingPackage]
        });
      }
    });
    return selectedPackages;
  }

  getFinalPrice() {
    return this.getSelectedPackages().map(e => e.pricingPackages.map(e => e.price)
      .reduce((sum, current) => sum + current, 0))
      .reduce((sum, current) => sum + current, 0);
  }

  getHighlightsOfPackage(downloadPackage: DownloadPackage): FeatureHighlight[] {
    const allHighlights: FeatureHighlight[] = [];
    downloadPackage.pricingPackages.forEach(pricingPackage => {
      pricingPackage.supportedFeatures.forEach(supportedFeature => {
        allHighlights.push(supportedFeature.featureHighlight);
      });
    });

    return uniqueArray(allHighlights);
  }

  findSupportedFeature(pricingPackage: PricingPackage, highlight: FeatureHighlight): SupportedFeature {
    return pricingPackage.supportedFeatures.find(e => e.featureHighlight === highlight);
  }

  getFeatureHighlightDescription(featureHighlight: FeatureHighlight): string {
    return StaticData.getFeatureHighlightDescription(featureHighlight);
  }

  onDownloadFormSubmit(): void {
    if (this.invoicingForm.invalid) {
      return;
    }
    this.submitInProgress = true;
    this.formSubmitError = undefined;

    this.recaptchaV3Service.execute('download').subscribe(token => {
      if (this.getFinalPrice() === 0) {
        this.apiService.sendFreeDownloadEmail({
          contactEmail: this.invoicingDetails.contactEmail,
          captcha: token
        }).subscribe(() => this.onFormSubmitSuccess(), error => this.onFormSubmitFailed());
      } else {
        this.apiService.sendPaidDownloadEmail({
          invoicingDetails: this.invoicingDetails,
          selectedPackages: this.getSelectedPackages(),
          captcha: token
        }).subscribe(() => this.onFormSubmitSuccess(), error => this.onFormSubmitFailed());
      }
    }, error => {
      console.error(error);
    });
  }

  private onFormSubmitSuccess(): void {
    this.submitInProgress = false;
    this.formSubmitError = false;
    this.invoicingForm.resetForm();
    this.modalService.open(this.formSubmitSuccessModal, {
      size: 'md',
      backdrop: true,
      centered: true
    });
  }

  private onFormSubmitFailed(): void {
    this.submitInProgress = false;
    this.formSubmitError = true;
    this.modalService.open(this.formSubmitErrorModal, {
      size: 'md',
      backdrop: true,
      centered: true
    });
  }
}
