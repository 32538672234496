export enum FeatureHighlight {
  METRICS = "Metrics",
  CLONES = "Clones (code duplication)",
  CPP_CHECK = "CppCheck",
  METRICS_HUNTER = "MetricsHunter",
  FAULT_HUNTER = "FaultHunter",
  USER_DEFINED_METRICS = "UserDefinedMetrics",
  SUPPORT = "Support",
  PMD_SPOTBUGS = "PMD + Spotbugs",
  VULNERABILITY_HUNTER = "VulnerabilityHunter",
  RTE_HUNTER = "RTEHunter",
  ANDROID_HUNTER = "AndroidHunter",
  FX_COP = "FxCop",
  PYLINT = "Pylint",
  ES_LINT = "ESLint"
}

export interface SupportedFeature {
  featureHighlight: FeatureHighlight;
  supported: boolean;
  remarks?: string[];
}
