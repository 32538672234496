import {Component} from '@angular/core';

@Component({
  selector: 'captcha-info',
  templateUrl: './captcha-info.component.html',
  styleUrls: ['./captcha-info.component.scss']
})
export class CaptchaInfoComponent {

}
