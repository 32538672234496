import {Component} from '@angular/core';

@Component({
  selector: 'sm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  currentYear = new Date().getFullYear();

  constructor() {
  }

}
