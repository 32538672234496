import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Feature} from "../model/feature";
import {NgForm} from "@angular/forms";
import {Router} from "@angular/router";
import {NavbarComponent} from "../shared/navbar/navbar.component";
import {ApiService} from "../backend/api/api.service";
import {News} from "strapi-lib/lib/model/news";
import {StrapiConfig, StrapiLibService} from "strapi-lib";
import {forkJoin} from "rxjs";
import {Pagination} from "strapi-lib/lib/pagination";
import {Reference} from "strapi-lib/lib/model/reference";
import {StaticData} from "../util/static-data";
import {HomeTextSlide} from "../model/home-text-slide";
import {isPlatformBrowser} from "@angular/common";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  @ViewChild(NavbarComponent)
  navbar: ElementRef;

  @ViewChild('featuresSection')
  featuresSection: ElementRef;

  @ViewChild('customersSection')
  customersSection: ElementRef;

  @ViewChild('contactSection')
  contactSection: ElementRef;

  newsItems: News[] = [];

  features: Feature[] = StaticData.getFeatures();

  @ViewChild('contactForm')
  contactForm: NgForm
  submitInProgress = false;
  errorContactSubmit = undefined;

  slides: HomeTextSlide[] = StaticData.getHomeTextSlides();
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    draggable: false,
    dots: true,
  };

  customers: Reference[] = [];
  customersSlideConfig = {
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: "<div class='nav-btn next-arrow'></div>",
    prevArrow: "<div class='nav-btn prev-arrow'></div>",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  constructor(
    private router: Router,
    private apiService: ApiService,
    private strapi: StrapiLibService,
    private strapiConfig: StrapiConfig,
    @Inject(PLATFORM_ID) private platformId: string,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      forkJoin({
        newsItems: this.strapi.newsService.list(['_limit=4']),
        references: this.strapi.referenceService.list()
      }).subscribe((res: { newsItems: Pagination<News>; references: Pagination<Reference>; }) => {
        if (res.newsItems.size) {
          this.newsItems = res.newsItems.content;
        }
        if (res.references.size) {
          this.customers = res.references.content;
        }
      }, error => {
        console.error(error);
      });
    }
  }

  getImageUrl(url: string): string {
    return `${this.strapiConfig.strapiUrl}${url}`;
  }

  onContactSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    const contact = form.value;
    contact.portal = this.strapiConfig.portal;

    this.submitInProgress = true;
    this.errorContactSubmit = undefined;

    this.recaptchaV3Service.execute('contact').subscribe(token => {
      this.apiService.verifyGoogleCaptcha({
        action: 'contact',
        captcha: token
      }).subscribe(isValid => {
        if (isValid) {
          this.strapi.contactService.createContact(contact).subscribe(
            (res) => {
              this.submitInProgress = false;
              this.errorContactSubmit = false;
              this.contactForm.resetForm();
            },
            (error) => {
              console.error(error);
              this.submitInProgress = false;
              this.errorContactSubmit = true;
            }
          );
        } else {
          this.submitInProgress = false;
          this.errorContactSubmit = true;
        }
      });
    }, error => {
      console.error(error);
      this.submitInProgress = false;
      this.errorContactSubmit = true;
    });
  }
}
