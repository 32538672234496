import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  readonly googleDocsUrl = 'https://docs.google.com/document/d/e/2PACX-1vS6wxKItZdLGw7zba8gYkENVRC9LbZZEC-I4bghmD9_9QbOFeA81G4iKrsesjv0O700uXLOXP6iIlTr/pub?embedded=true';
  document: SafeHtml;

  constructor(private httpClient: HttpClient,
              private sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.httpClient.get(this.googleDocsUrl, {
          responseType: 'text'
        }
      ).subscribe(documentContent => {
        // Create script tag for iframe resizer
        const script = document.createElement('script');
        script.src = 'assets/js/iframeResizer.contentWindow.js';

        // Create a tagged element for with data-iframe-height attribute for iframe-resizer
        const taggedDocumentBottom = document.createElement('div');
        taggedDocumentBottom.style.clear = 'both';
        taggedDocumentBottom.setAttribute('data-iframe-height', '');

        const resizableContent = documentContent.concat(taggedDocumentBottom.outerHTML).concat(script.outerHTML);
        this.document = this.sanitizer.bypassSecurityTrustHtml(resizableContent);
      });
    }
  }

}
