import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {StrapiConfig, StrapiLibService} from 'strapi-lib';
import {News} from 'strapi-lib/lib/model/news';
import {Pagination} from 'strapi-lib/lib/pagination';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

interface IArticle extends News {
  opened: boolean;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  news: IArticle[] = [];
  selectedNews: IArticle;
  pageIndex = 0;
  pageSize = 4;
  total: number;
  lastPage = false;

  loadingNextPage = false;

  @ViewChild('newsContent')
  newsModal: ElementRef;

  constructor(
    private router: Router,
    private strapi: StrapiLibService,
    private strapiConfig: StrapiConfig,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      forkJoin([
        this.strapi.newsService.count(),
        this.strapi.newsService.list([`_start=${this.pageIndex * this.pageSize}`, `_limit=${this.pageSize}`])
      ]).subscribe(([count, news]) => {
        this.total = count;
        this.processData(news);
      });
    }
  }

  private fetchData(): void {
    this.strapi.newsService.list([`_start=${this.pageIndex * this.pageSize}`, `_limit=${this.pageSize}`])
      .subscribe(news => this.processData(news));
  }

  private processData(news: Pagination<News>): void {
    this.news.push(
      ...news.content.map(article => {
        (article as IArticle).opened = false;
        article.media.url = `${this.strapiConfig.strapiUrl}${article.media.url}`;
        return article as IArticle;
      })
    );
    if ((this.pageIndex + 1) * this.pageSize >= this.total) {
      this.lastPage = true;
    }
    this.loadingNextPage = false;
  }

  onLoadMore(): void {
    this.loadingNextPage = true;
    this.pageIndex++;
    this.fetchData();
  }

  getImageUrl(url: string): string {
    return `${this.strapiConfig.strapiUrl}${url}`;
  }

  openArticle(news: IArticle): void {
    this.selectedNews = news;
    const openedModal = this.modalService.open(this.newsModal, {
      size: 'lg',
      backdrop: true,
      centered: true,
      windowClass: 'news-window'
    });
    openedModal.result.finally(() => {
      this.selectedNews = null;
    });
  }
}
