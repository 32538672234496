<sm-navbar [activeItem]="'News'"></sm-navbar>

<section class="section">
  <img src="assets/section_divider.svg" class="section-divider" alt="Section divider">
  <h2 class="section__heading bold">News</h2>

  <div class="text-left news">
    <div class="flex-container">
      <div class="col-2" *ngFor="let newsItem of news">
        <div class="article-preview">
          <div class="article-preview__image-wrapper">
            <img class="article-preview__image-wrapper__img"
                 [src]="getImageUrl(newsItem.media?.formats?.thumbnail.url)"
                 [alt]="newsItem.media?.alternativeText || 'Article image'"/>
          </div>
        </div>
        <div class="article-preview__content">
          <p class="news-title">{{ newsItem.title }}</p>
          <p [innerHtml]="newsItem.abstractContent" class="text-gray"></p>
          <button class="btn btn-outline-primary action-button action-button--outline bold"
                  (click)="openArticle(newsItem)">Read more
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center" *ngIf="!lastPage">
    <button class="btn btn-outline-primary action-button action-button--outline bold" [disabled]="loadingNextPage"
            (click)="onLoadMore()">
      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
            *ngIf="loadingNextPage"></span>
      SHOW MORE
    </button>
  </div>
</section>

<ng-template #newsContent let-modal>
  <div class="modal-body news-modal">
    <img src="assets/news_cancel.svg" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
         height="20" width="20" alt="Close">
    <div class="news-modal__content">
      <p class="news-title bold">{{ selectedNews.title }}</p>
      <p [innerHtml]="selectedNews.content" class="text-gray"></p>
      <div class="keywords" *ngIf="selectedNews.keywords.length">
        <span class="keywords__label text-gray">Keywords:</span>
        <span class="keywords__word text-gray" *ngFor="let word of selectedNews.keywords">{{word.name}}</span>
      </div>
    </div>
  </div>
</ng-template>
