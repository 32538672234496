import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home/home.component';
import {FormsModule} from '@angular/forms';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {DownloadComponent} from './download/download.component';
import {NewsComponent} from './news/news.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {ApiService} from './backend/api/api.service';
import {HttpClientModule} from '@angular/common/http';
import {StrapiConfig, StrapiLibModule, StrapiLibService} from 'strapi-lib';
import {environment} from '../environments/environment';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {NavigationFooterComponent} from './shared/navigation-footer/navigation-footer.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import { FooterComponent } from './shared/footer/footer.component';
import { CaptchaInfoComponent } from './shared/captcha-info/captcha-info.component';
import {IFrameResizerDirective} from './terms-of-use/i-frame-resizer.directive';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DownloadComponent,
    NewsComponent,
    NavbarComponent,
    TermsOfUseComponent,
    NavigationFooterComponent,
    FooterComponent,
    CaptchaInfoComponent,
    IFrameResizerDirective
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    NgbModule,
    FormsModule,
    SlickCarouselModule,
    HttpClientModule,
    StrapiLibModule,
    LazyLoadImageModule,
    RecaptchaV3Module
  ],
  providers: [
    ApiService,
    StrapiLibService,
    {
      provide: StrapiConfig,
      useValue: {
        portal: 'SM',
        environment: environment.environment,
        strapiUrl: 'cms'
      }
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaSiteKey },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
