import {AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'sm-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

  collapsed = true;

  @ViewChild('navbar')
  navbar: ElementRef;

  @ViewChild('background')
  navbarBackground: ElementRef;

  @Input()
  transparent = false;

  @Input()
  activeItem: string;

  fragmentSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  private onScroll(event): void {
    if (this.transparent) {
      const alphaValue = event.target.scrollingElement.scrollTop / 150;
      this.changeNavbarAlpha(alphaValue);
    }
  }

  private changeNavbarAlpha(alpha: number): void {
    this.navbarBackground.nativeElement.style.opacity = alpha;
  }

  ngAfterViewInit(): void {
    if (this.transparent) {
      this.changeNavbarAlpha(0);
    }

    this.fragmentSubscription = this.route.queryParams.subscribe(data => {
      setTimeout(() => {
        if (data.section) {
          this.onScrollToSection(data.section);
          this.router.navigate([], {
            queryParams: {
              section: null,
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        }
      }, 200);
    });
  }

  onScrollToSection(hash): void {
    if (this.router.url === '/' || this.router.url.indexOf('/?section=') === 0) {
      document.getElementById(hash).scrollIntoView({behavior: 'smooth', block: 'center'});
    } else {
      this.router.navigate([''], {queryParams: {section: hash}});
    }
  }


}
